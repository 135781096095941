<template>
  <div
    class="hero-banner__info border-inherit touch-none pointer-events-none"
    @pointerdown.self.stop="onPointerdown"
    @pointermove="onPointermove"
  >
    <slot name="logo">
      <img
        v-if="item.images?.logo"
        :src="item.images?.logo"
        id="logo"
        class="max-h-[3rem] md:max-h-[3rem] lg:max-h-[8rem] w-auto"
      />
    </slot>

    <div
      :key="item?.externalId"
      :class="[
        { 'opacity-0': isTrailerStarted },
        { 'opacity-100': isUiShowing },
      ]"
      id="assetInfo"
      class="md:mt-8 flex flex-col gap-y-4 transition duration-200 touch-auto pointer-events-auto"
    >
      <div>
        <div class="flex flex-col gap-y-2">
          <slot name="tags">
            <OverlayFieldTags :media="item"></OverlayFieldTags>
            <OverlayDescriptionTags
              :media="item"
              opacity-class="opacity-80"
            ></OverlayDescriptionTags>
          </slot>
        </div>
        <OverlayDescription
          v-if="!isXs"
          :media="item"
          :in-detail="inDetail"
          @on-description-modal="onDescriptionModal"
        ></OverlayDescription>
      </div>
    </div>
    <div
      id="cta"
      class="flex flex-col gap-y-4"
    >
      <OverlayCtaSection
        :key="item?.externalId"
        :media="item"
        :class="[
          { 'opacity-0': isTrailerStarted },
          { 'opacity-100': isUiShowing },
        ]"
        :in-detail="inDetail"
        class="md:mt-6 touch-auto pointer-events-auto"
        @pointerenter="preventHideUi = true"
        @pointerleave="preventHideUi = false"
        gtmTitleLabel="hero banner"
      >
        <template #primary>
          <slot name="primary"></slot>
        </template>
        <template #extraIcons>
          <slot name="extraIcons"></slot>
        </template>
      </OverlayCtaSection>
      <slot name="cta-suffix"></slot>
    </div>
    <div
      v-if="!inDetail"
      id="navigator"
      class="mt-6"
    >
      <slot name="navigator"></slot>
    </div>
    <div
      id="controller"
      class="flex items-end justify-end"
    >
      <slot name="controller"></slot>
    </div>
  </div>
</template>

<script setup>
import { PLAYERS } from "@/constants";
const props = defineProps({
  item: Object,
  isTrailerStarted: Boolean,
  inDetail: Boolean,
});

const { isXs } = useBreakpoints();

const hideTimeout = ref();
const isUiShowing = ref(true);
const preventHideUi = ref(false);

const hideUi = () => {
  if (preventHideUi.value) return;
  hideTimeout.value = setTimeout(() => {
    hideTimeout.value && clearTimeout(hideTimeout.value);
    isUiShowing.value = false;
  }, 2000);
};

const onPointerdown = ({ pointerType }) => {
  if (pointerType == "touch") {
    isUiShowing.value = !isUiShowing;

    if (isUiShowing) {
      hideUi();
    }
  }
};
const onPointermove = ({ pointerType }) => {
  if (pointerType == "touch") return;
  isUiShowing.value = true;
  hideUi();
};

const onDescriptionModal = (value) => {
  if (value) {
    useAllPlayers().pauseAll();
    isUiShowing.value = true;
    preventHideUi.value = true;

    return;
  }

  const detailPlayer = useAllPlayers().getStore(PLAYERS.DETAIL_PLAYER);
  detailPlayer?.play();
  preventHideUi.value = false;
  hideUi();
};

onBeforeUnmount(() => {
  hideTimeout.value && clearTimeout(hideTimeout.value);
});
</script>

<style lang="scss" scoped></style>
